import React from 'react'

export default function Plates(props)
{   
    function checkPlate()
    {   
        
        if(props.maxCount !== 3)
        {
            return props.plateState ? "plate-big plate-"+props.id+" selected" : "plate-big plate-"+props.id+" notselected" 
        }
        else
        {
            if(props.selected)
            {
                return props.plateState ? "plate plate-"+props.id+" selected" : "plate plate-"+props.id+" notavailable" 
            }
            else
            {
                return props.plateState ? "plate-big plate-"+props.id+" selected" : "plate-big plate-"+props.id+" notavailable"
            }
        }

    }
    
    return(
        <div 
            onClick={(event) => props.handleClick(props.id)} 
            className={checkPlate()}>
        </div>
    )
}