import React from 'react'
import './App2.css';
import Plates from './Components/Plates';
import Locations from './Components/Locations';
import Section from './Components/Section';
import Data from './data.json'
import UseWindowDimensions from './Components/UseWindowDimensions';

function App2(props) {

  const[count, setCount] = React.useState(0)

  function run343()
  {
    alert("thank you")
  }

  return (
    <div className="App2">
      
      <div className='tilt-holder'>
        <div className='tilt-seperator'></div>
      </div>
      
      <div className='holder'>
        
        <img className="main-logo" src='../Assets/main-logo.png' alt='Destiny 2 : Raid Buddy Tools'/>
        <img onClick={props.show343} className="bW9yYml1cw" src='../Assets/01101000011001010110110001110000.png' alt='' />

        <div className='title'>

          <h1>Need help <br />
              <large>raiding?</large>
          </h1>

          <p>We're here to help, with simple guides, infographics and tools to help guide you through many challenges. <br /><br /> Alternately you can join us discord channel:</p>
          <a href="https://discord.gg/9xaYwGzWzZ"><img src='../Assets/discord_link.png'className='discordLink'></img></a>
        </div>

      <Section sectionType='kingsfall' handleClick={props.kingsfallBtn} />

      </div>
    
    </div>
  );

}

export default App2;