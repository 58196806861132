import React from 'react'

export default function Selection(props)
{  

    return(
        <div onClick={props.handleClick} className={props.sectionType == "kingsfall" && "section-holder kingsfall-bg"}>
            <div>
                <img src='../Assets/kingsfall-chest-logo.png' className='sectionLogo'></img>
                <p>Follow a simple easy process to get the secret chest at the end of the raid.</p>
            </div>
        </div>
    )
}

