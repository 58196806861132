import React from 'react'
import './App.css';
import Plates from './Components/Plates';
import Locations from './Components/Locations';
import Data from './data.json'
import UseWindowDimensions from './Components/UseWindowDimensions';

function App(props) {

  const[count, setCount] = React.useState(0)
  const[current, setCurrent] = React.useState(0)
  const[plates, setPlates] = React.useState([false,false,false,false,false,false,false,false,false])
  const[checked, setChecked] = React.useState([])
  const[list, setList] = React.useState([])
  const[selected, setSelected] = React.useState(false)

  const specialNumer = list[current]
  const newArray = []
  const {height, width} = UseWindowDimensions();
  
  function clickingPlate(event)
  {
    let clickedOn = event-1

    plates.map((plate, index) => 
    {
      if(index === clickedOn){
        if(plate === true){

          newArray.push(false)
          
          const newList = list.filter(m => {
            return m !== index;
          })

          newList.sort()
          setList(newList)
          
          setCount(old => old-1)
        }
        else{
          if(count < 3){
              const newList = list

              const exists = list.some(element => {
                if(element === index)return true;
                return false;
              })

              if(exists){ 
                console.log("It already is in list") 
              }
              else{
                newArray.push(true)
                newList.push(index)
                
                newList.sort()
                setList(newList)
                setCount(old => old+1)
              }

          }
          else
          {
            newArray.push(plate)
          }
        } 
      }
      else{
        newArray.push(plate)
      }
      return null
    })

    setPlates(newArray)
    setCurrent(0)
  }

  const handleChecked = () => {

    if(current < 2) setCurrent(prev => prev+1)

  }

  function searchSymbols()
  {
    setSelected(true);
  }

  function reset()
  {
    setSelected(false)
    setList([])
    setCount(0)
    setCurrent(0)
    setPlates([false,false,false,false,false,false,false,false,false])
  }

  const special1 = list[0];
  const special2 = list[1];
  const special3 = list[2];
  
  return (
    <div className="App">

      <img className="logo" onClick={props.backBtn} src='../Assets/Logo.png' alt='Destiny 2 : Raid Buddy Tools - Kings Fall'/>
      <img src='../Assets/find-symbols.gif' className={selected ? 'hide' : 'introImage'} alt=''/>
      
      <p className={selected ? 'hide' : 'pageText'}>PICK THE SYMBOLS FOUND IN THE COURT OF ORYX</p>

      <div className={selected ? '' : 'pageText'}>
        <Plates id={1} selected={selected} plateState={plates[0]} handleClick={clickingPlate} maxCount={count} isChecked={checked[0]} addToList={setList} />
        <Plates id={2} selected={selected} plateState={plates[1]} handleClick={clickingPlate} maxCount={count} isChecked={checked[1]} addToList={setList} />
        <Plates id={3} selected={selected} plateState={plates[2]} handleClick={clickingPlate} maxCount={count} isChecked={checked[2]} addToList={setList} />
        <Plates id={4} selected={selected} plateState={plates[3]} handleClick={clickingPlate} maxCount={count} isChecked={checked[3]} addToList={setList} />
        <Plates id={5} selected={selected} plateState={plates[4]} handleClick={clickingPlate} maxCount={count} isChecked={checked[4]} addToList={setList} />
        <Plates id={6} selected={selected} plateState={plates[5]} handleClick={clickingPlate} maxCount={count} isChecked={checked[5]} addToList={setList} />
        <Plates id={7} selected={selected} plateState={plates[6]} handleClick={clickingPlate} maxCount={count} isChecked={checked[6]} addToList={setList} />
        <Plates id={8} selected={selected} plateState={plates[7]} handleClick={clickingPlate} maxCount={count} isChecked={checked[7]} addToList={setList} />
        <Plates id={9} selected={selected} plateState={plates[8]} handleClick={clickingPlate} maxCount={count} isChecked={checked[8]} addToList={setList} />
      </div>

      <div className={selected ? 'hide' : ''}> 
        { count < 3 && <button className='introButton' onClick={searchSymbols} disabled><h4>SELECT SYMBOLS {count}/3</h4></button> }
        { count === 3 && <button className='introButton' onClick={searchSymbols}><h4>SEARCH</h4></button> }
      </div>

      <div className={selected ? 'hide' : 'hide'}>
        <div className='left-dot'></div>
        <div className='middle-dot'></div>
        <div className='right-dot'></div>
      </div>
      
      <div className={selected ? 'plates' : 'hide'}>

        { width < 600 && plates[specialNumer] && (<Locations data={Data[specialNumer]}/>)}

        { width > 600 && special1 != null && (<Locations data={Data[special1]}/>) }
        { width > 600 && special2 != null && (<Locations data={Data[special2]}/>) }
        { width > 600 && special3 != null && (<Locations data={Data[special3]}/>) }


      </div>

      <div className='overlay'></div>
      
      <div className='bottomUI'>
        <div onClick={reset} className={selected ? 'buttonBack' : 'hide'}>Back</div>
      
      {current < 2 &&
      <div className={selected ? 'button' : 'hide'} onClick={handleChecked}>
          <p>STEP {current+1} : {Data[specialNumer == null ? 0 : specialNumer].title}</p>
           <h4>COMPLETED?</h4>
      </div>
      }
      {current >= 2 &&
      <div onClick={reset} className={selected ? 'finished' : 'hide'}>
           <h4>ALL DONE!</h4>
      </div>
      }
      </div>

    </div>
  );

}

export default App;