import React from 'react'
import './App3.css';
import Plates from './Components/Plates';
import Locations from './Components/Locations';
import Section from './Components/Section';
import Data from './data.json'
import UseWindowDimensions from './Components/UseWindowDimensions';

function App3(props) {

  const[count, setCount] = React.useState(0)
  const[words1, setWord1] = React.useState("H̷̩̤̎̏̈̓e̵͆l̴̛̈́́̓p̸̛͈̝̺̏̽̿̉͗ ̸̨͑̕m̸̳̻̘͊̉̔̆é̶̙̞͍͈̯̗͈̑")
  const[words2, setWord2] = React.useState("H̷̘͖͔͙̗͍̤͎̜͒̓ë̴̡̙͕͚́͊͗͒͊͝l̷͎͎̒͝p̴̥̥̜̬̼͎̏̔͊͝ ̷͇̣̮͔͂͐Y̵̯̯̘̳̖̌̓̊͛̽ö̵͕̹̔̀̏͋̅̋̈͑̍u̵̧̡̞͔̤͋̈̆̓̑͒̚")

  const[console, setConsole] = React.useState("");
  const[newWindow, setNewWindow] = React.useState(false);
  const[rt7873, setRt7873] = React.useState(false);

  const[evidenceOne, setEvidenceOne] = React.useState("");
  const[evidenceTwo, setEvidenceTwo] = React.useState("");
  const[evidenceThree, setEvidenceThree] = React.useState("");

  const[ev1, setEv1] = React.useState(false);
  const[ev2, setEv2] = React.useState(false);
  const[ev3, setEv3] = React.useState(false);

  function reviewCode(e){ setConsole(e.target.value); }

  function evi1Review(e){ setEvidenceOne(e.target.value); }
  function evi2Review(e){ setEvidenceTwo(e.target.value); }
  function evi3Review(e){ setEvidenceThree(e.target.value); }

  (function(_0x77ab58,_0x3a4461){var _0x1d7990=_0x1342,_0x2b243a=_0x77ab58();while(!![]){try{var _0x2e6a0c=parseInt(_0x1d7990(0x13e))/0x1*(parseInt(_0x1d7990(0x13d))/0x2)+parseInt(_0x1d7990(0x13a))/0x3+-parseInt(_0x1d7990(0x135))/0x4*(parseInt(_0x1d7990(0x139))/0x5)+parseInt(_0x1d7990(0x13c))/0x6+parseInt(_0x1d7990(0x138))/0x7+-parseInt(_0x1d7990(0x13b))/0x8+parseInt(_0x1d7990(0x137))/0x9;if(_0x2e6a0c===_0x3a4461)break;else _0x2b243a['push'](_0x2b243a['shift']());}catch(_0x38e450){_0x2b243a['push'](_0x2b243a['shift']());}}}(_0xe7ee,0x47edb));function _0xe7ee(){var _0x4369a2=['2654154elueFB','2tsjOBL','75906wrVxOn','Fruchtmus','D2-NightSQL','1598252AlXgYb','000230','140904rWqWAc','1042468YmMdJW','5cjtYRY','1636500jsUdtq','4273304pHWjIt'];_0xe7ee=function(){return _0x4369a2;};return _0xe7ee();}function checkCode(){var _0x5d04a7=_0x1342;console==_0x5d04a7(0x134)?setNewWindow(!![]):setNewWindow(![]);}function _0x1342(_0x14ec72,_0x1b0d6a){var _0xe7eedc=_0xe7ee();return _0x1342=function(_0x13420a,_0x35f35c){_0x13420a=_0x13420a-0x134;var _0x48f6cc=_0xe7eedc[_0x13420a];return _0x48f6cc;},_0x1342(_0x14ec72,_0x1b0d6a);}function runQuery(){var _0x4a0df4=_0x1342;evidenceOne=='M-205-708-123'&&evidenceTwo==_0x4a0df4(0x13f)&&evidenceThree==_0x4a0df4(0x136)?setRt7873(!![]):setRt7873(![]);}

  return (
    <div className="App3">
      
      <div className='blackoverlay'></div>

      <div className="wordHover">
        <h1>{words1}</h1>
        <div className="glitch">
          <div className="overflow"><div className="large top">{words2}</div></div>
          <div className="overflow"><div className="large bottom">{words2}</div></div>
        </div>
      </div>

      {
        newWindow && 
        <div className='newWindow'>
          <div className='infoPanel'>NightSQL Database V5.7a db78376182089</div>
          <div className='evidencefield'>
            <div className='break'>SELECT <yellow>*</yellow> FROM <yellow>`database`</yellow></div>
            <div className='break'> WHERE <yellow>`rations_m`</yellow> LIKE <yellow>`</yellow>
            <input type="text" name="name" className='evidencefieldinput s1' placeholder='Evidence 0.1' onChange={(e) => evi1Review(e)} autofocus/>
            <yellow>`</yellow></div>
          </div>
          <div className='evidencefield'>
            <div className='break'> AND <yellow>`rations_brand`</yellow> LIKE <yellow>`</yellow>
            <input type="text" name="name" className='evidencefieldinput s1' placeholder='Evidence 0.2' onChange={(e) => evi2Review(e)} autofocus/>
            <yellow>`</yellow></div>
          </div>
          <div className='evidencefield'> AND <yellow>`container_id`</yellow> LIKE <yellow>`</yellow></div>
          <input type="text" name="name" className='evidencefieldinput s1' placeholder='Evidence 0.3' onChange={(e) => evi3Review(e)} autofocus/>
          <div className='evidencefield'><yellow>`</yellow></div>
          <button onClick={runQuery}>Run Query</button>
        </div>
      }

      {
        newWindow && rt7873 &&
        <div className='newWindowResults'>
          <div className='infoPanel'>Showing rows 0 - 1 (1 total, Query took 0.0014 seconds.)</div>
          <div className='rowH'>
              <div className='column'>id</div>
              <div className='column'>signed</div>
              <div className='column'>faction</div>
              <div className='column'>job_lot</div>
              <div className='column'>qty</div>
              <div className='column'>list_id</div>
          </div>
          <div className='row'>
              <div className='columnR'>587</div>
              <div className='columnR'>S.Turly</div>
              <div className='columnR'>New Monarchy</div>
              <div className='columnR'>23548745</div>
              <div className='columnR'>340</div>
              <div className='columnR'>4578</div>
          </div>
        </div>
      }

      {
        newWindow && !rt7873 &&
        <div className='newWindowResults'>
        <div className='infoPanel'>Showing rows 0 - 0 (0 total, Query took 0.0000 seconds.)</div>
        <div className='row'>No results found.</div>
        </div>
      }

      <div className='console'>
        <input type="text" name="name" className='consolefield' placeholder='awaiting code..' onChange={(e) => reviewCode(e)} autofocus/>
        <button onClick={checkCode}>Send</button>
      </div>

    </div>
  );

}

export default App3;