import React from 'react'

export default function Locations(props)
{   
    const [playgif, setPlaygif] = React.useState(false);
    let loaded = false;

    function didClick(){
        setPlaygif(!playgif);
    }
    
    function handleSource()
    {
        if(playgif)
        {
            return props.data.animated
        }
        else
        {
            //return Play
            return props.data.animated
        }  
    }
    
    function loadedNew()
    {
        console.log("panel loaded "+props.data.id)
        loaded = true;
    }

    function updateFade()
    {
        if(loaded === false)
        {
            return "location"
        }
        else
        {
            return "location fadeIn"
        }
    }

    return(
        <div id={props.data.id} onChange={loadedNew()} className={updateFade()}>

            <img onClick={didClick} className={props.isChecked ? "hide" : "scaleImage"} src={handleSource()} alt=''/>

            <div>
                <div className='icon'>
                    <img src={props.data.icon} alt=''/>
                </div>
                <div className='details'>
                    <h5 className={props.isChecked ? "subdued" : ""}>SYMBOL {props.data.relic}</h5> 
                    <h2>{props.data.title}</h2>
                </div>
            </div>

            <p className={props.ischecked ? "subdued" : ""}>{props.data.description}</p>
            <p className={props.ischecked ? "subdued" : ""}>{props.data.note}</p>
            
        </div>
    )
}