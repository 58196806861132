import React from 'react'
import './index.css';
import Plates from './Components/Plates';
import Locations from './Components/Locations';
import Data from './data.json'
import UseWindowDimensions from './Components/UseWindowDimensions';
import App from './App';
import App2 from './App2';
import App3 from './App3';

export default function Main() {

  const[page, setPage] = React.useState(0)

  function kingsfallBtn()
  {
    setPage(1);
  }

  function show343()
  {
    setPage(2);
  }

  function backBtn()
  {
    setPage(0);
  }

  return (

    <div className={ 
      page == 0 && "mainBackground" || 
      page == 1 && "kingsfallBackground" ||
      page == 2 && "run343"
    }>

    { page == 0 && <App2 kingsfallBtn={kingsfallBtn} show343={show343}/> }
    { page == 1 && <App backBtn={backBtn}/> }
    { page == 2 && <App3 /> }

    </div>

  );

}